import React, { useContext, useEffect, useState } from "react";
import { PillProps } from "../../estate-type";

export const Pill = ({ color, bgColor, text }: PillProps) => {
  return (
    <button className="py-2 px-4 shadow-md no-underline rounded-full bg-indigo-500 text-white font-sans font-semibold text-sm border-blue btn-primary hover:text-white hover:bg-blue-light focus:outline-none active:shadow-none mr-2">
      {text}
    </button>
  );
};
