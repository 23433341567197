import React, { useContext, useEffect, useState, useMemo } from "react";
import { DataType, DataContextType, SubmissionType } from "../../estate-type";
import EstateContext from "../../estate-context";

import ListingItem from "../molecules/ListItem";
import DataTable from "../molecules/DataTable";
import { DebugTableWeb } from "../organisms/DebugTableWeb";
import { stringify } from "querystring";

function TemplateAdminSubmission() {
  const {
    data,
    currentLine,
    currentStation,
    setCurrentLine,
    setCurrentStation,
    addToCart,
    cartItems,
    removeFromCart,
    localSubmission,
  } = useContext<DataContextType>(EstateContext);

  const data1 = useMemo(() => {
    let result: any[] = [];
    if (localSubmission.length > 0) {
      // Flatten into row
      localSubmission.map((o: SubmissionType, idx: number) => {
        let oneFlatSubmission: { [key: string]: string } = {};
        Object.keys(o).map((key1: string) => {
          if (-1 !== ["ts"].indexOf(key1)) {
            oneFlatSubmission[key1] = o[key1];
            return;
          }
          Object.keys(o[key1]).map((key2: string) => {
            oneFlatSubmission[`${key2}`] = o[key1][key2];
          });
        });
        result.push(oneFlatSubmission);
      });
    }
    return result;
  }, [localSubmission]);

  const columns1 = useMemo(() => {
    let cols: { [key: string]: string }[] = [];
    if (localSubmission.length > 0) {
      // Prepare column header
      let firstSubmission: SubmissionType = localSubmission[0];
      Object.keys(firstSubmission).map((key1: string) => {
        if (-1 !== ["ts"].indexOf(key1)) {
          cols.push({
            Header: `${key1}`,
            accessor: `${key1}`,
          });
          return;
        }
        Object.keys(firstSubmission[key1]).map((key2: string) => {
          cols.push({
            Header: `${key2}`,
            accessor: `${key2}`,
          });
        });
      });
    }
    return cols;
  }, [localSubmission]);

  return (
    <>
      <h1 className="w-full p-4 text-2xl font-bold text-indigo-400">
        Admin: Submission
      </h1>

      <DataTable columns={columns1} data={data1} />

      {/* <DebugTableWeb vars={{
      'items': [
        {
          key: 'localSubmission',
          value: localSubmission,
          type: 'JSON'
        },
      ]
    }} /> */}
    </>
  );
}

export default TemplateAdminSubmission;
