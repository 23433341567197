const lang = {
  "app.header": "Modifiez les fichiers et enregistrez-les pour recharger",
  "app.slogan": "Next Station Flat",
  "common.monetaryunit": "Monthly HKD",
  "common.goback": "Back",
  "common.station": " Station",
  "common.tour.done": "Done",
  "common.tour.close": "Close",
  "common.tour.next": "Next",
  "common.tour.previous": "Previous",
  "nav.home": "Home",
  "nav.saved": "Saved",
  "nav.adminsubmission": "Admin panel",
  "nav.useregister": "Sign Up",
  "nav.login": "Login",
  "nav.unloginname": "Not logged in",
  "footer.contactus": "聨絡我們",
  "district.labelsort": "Filter: ",
  "district.labelline": "Choose Line",
  "district.labelstation": "Choose Station",
  "pagination.prev": "Previous",
  "pagination.next": "Next",
  "user.registerheading": "User Registration",
  "user.userid": "Choose Your User ID",
  "user.password": "Password",
  "user.passwordchoose": "Please choose a password.",
  "user.passwordconfirm": "Confirm Password",
  "user.username": "Display Name",
  "user.email": "Email Address",
  "user.mobile": "Mobile Phone Number",
  "user.image": "User Profile Picture",
  "user.registernow": "Register",
  "user.haveaccountlogin": "Already have an account? Login!",
  "user.loginheading": "User Login",
  "login.userid": "Userid",
  "login.password": "Password",
  "login.login": "Login",
  "login.loginerror": "Please check Userid and Password and retry.",
  "listing.matchedcritera1": "Matched results: ",
  "listing.matchedcritera2": " ",
  "order.heading": "Contact agent/owner",
  "order.interesteditem": "Interested item *",
  "order.inquirytext": "Inquiry text *",
  "order.additional_request": "Additional Requests from Owner",
  "order.submitinquiry": "遞交",
  "estate.station": "Station",
  "estate.id": "ID",
  "estate.description": "Description",
  "estate.contactperson": "Agent/owner",
  "estate.geolocation": "GeoLocation",
  "estate.distance": "Distance",
  "estate.price": "Price",
  "estate.contact": "Contact",
  "estate.bookmark": " Wishlist",
  "estatecard.spacious": "Area (saleable)",
  "estatecard.built": "Built ",
  "estatecard.year": "years",
  "estatecard.allowpets": "Allow pets",
  "estatecard.allowsmoke": "Allow smkoking",
  "estatecard.address": "Address: ",
  "modal.close": "Close",
  "modal.title": "Oops",
  "usercard.identity": "Agent",
  "home.tour.filter.heading": "快速找車站附近盤",
  "home.tour.filter.description": "先路線，後車站。",
  "home.tour.bookmark.heading": "書簽功能",
  "home.tour.bookmark.description": "想記下回頭再看就按下這個按鈕。",
  "home.tour.contact.heading": "聯絡代理",
  "home.tour.contact.description": "想了解多些不要等了！",
};

export default lang;
