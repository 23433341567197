import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Select from "react-select";

import {
  BASEURL,
  APIURL,
  lines,
  stations,
  listEstate,
  apiQueryGetEstate,
  addOrder,
} from "../../api/api";
import { DataContextType } from "../../estate-type";
import EstateContext from "../../estate-context";

const StationPicker = () => {
  const intl = useIntl();

  const {
    data,
    contextLang,
    setContextLang,
    currentLine,
    currentStation,
    setCurrentLine,
    setCurrentStation,
    setPage,
    isLoadingList,
    setIsLoadingList,
  } = useContext<DataContextType>(EstateContext);

  return (
    <div className="gFilter mb-4 p-4 border shadow-lg">
      <div className="flex w-full items-end">
        <div className="w-3/5 relative z-0">
          {0 !== currentLine ? (
            <button
              className="-mt-2 mr-0 w-6 h-6 leading-none absolute top-0 right-0 border-2 rounded-full bg-white"
              onClick={() => {
                setCurrentLine(0);
                setCurrentStation(0);
              }}
            >
              x
            </button>
          ) : null}
          <p className="inline-block">
            {intl.formatMessage({
              id: "district.labelsort",
              defaultMessage: "...",
            })}
          </p>
          <select
            className="w-8/12 p-2 rounded"
            value={currentLine}
            onChange={(e) => {
              setCurrentLine(parseInt(e.target.value));
              setCurrentStation(0);
            }}
          >
            <option value={0}>
              {intl.formatMessage({
                id: "district.labelline",
                defaultMessage: "...",
              })}
            </option>
            {lines
              ? lines.map((o: any, idx: number) => {
                  return (
                    <option
                      className={"Railline Lines-" + o.id}
                      value={o.id}
                      key={idx}
                    >
                      {contextLang === process.env.REACT_APP_DEFAULT_LANG
                        ? o.cname
                        : o.ename}
                    </option>
                  );
                })
              : null}
          </select>
        </div>

        <div className="w-2/5 relative z-0">
          {0 !== currentLine ? (
            <>
              {0 !== currentStation ? (
                <button
                  className="-mt-2 mr-0 w-6 h-6 leading-none absolute top-0 right-0 border-2 rounded-full bg-white"
                  onClick={() => {
                    setCurrentStation(0);
                  }}
                >
                  x
                </button>
              ) : null}
              <select
                className="p-2 rounded"
                value={currentStation}
                onChange={(e) => {
                  setIsLoadingList(true);
                  setCurrentStation(parseInt(e.target.value));
                  setPage(0);
                }}
              >
                <option value={0}>
                  {intl.formatMessage({
                    id: "district.labelstation",
                    defaultMessage: "...",
                  })}
                </option>

                {stations
                  ? stations
                      .filter((o: any) => o.line === currentLine)
                      .map((o: any, idx: number) => {
                        return (
                          <option value={o.id} key={idx}>
                            {contextLang === process.env.REACT_APP_DEFAULT_LANG
                              ? o.cname
                              : o.ename}
                          </option>
                        );
                      })
                  : null}
              </select>
            </>
          ) : (
            <span>&nbsp;</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default StationPicker;
