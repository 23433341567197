import React, { useContext, useEffect, useState, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { DataType, DataContextType } from "../../estate-type";
import EstateContext from "../../estate-context";

import ListingItem from "../molecules/ListItem";
import DataTable from "../molecules/DataTable";
import Pagination from "../molecules/Pagination";

const DisplayListing = () => {
  const intl = useIntl();

  const {
    data,
    setData,
    currentLine,
    currentStation,
    setCurrentLine,
    setCurrentStation,
    addToCart,
    cartItems,
    removeFromCart,
    page,
    setPage,
    size,
    setSize,
    isLoadingList,
    setIsLoadingList,
    listEstate,
    apiQueryGetEstate,
  } = useContext<DataContextType>(EstateContext);
  let history = useHistory();

  // const data0 = useMemo(
  //   () => [
  //     {
  //       col1: 'Hello',
  //       col2: 'World',
  //     },
  //     {
  //       col1: 'react-table',
  //       col2: 'rocks',
  //     },
  //     {
  //       col1: 'whatever',
  //       col2: 'you want',
  //     },
  //   ],
  //   []
  // )

  const data1 = useMemo(() => data.estates, [data]);

  // const columns0 = useMemo(
  //   () => [
  //     {
  //       Header: 'Column 1',
  //       accessor: 'col1', // accessor is the "key" in the data
  //     },
  //     {
  //       Header: 'Column 2',
  //       accessor: 'col2',
  //     },
  //   ],
  //   []
  // )

  useEffect(() => {
    history.push(`/?#${currentStation}/${size}/${page}`);
  }, [page, size, currentStation]);

  // const columns1 = useMemo(() => {
  //   console.log('columns1', data)

  //   return Object.keys(data.estates[0] || []).map((o :string, idx: number) => {
  //     if (-1 !== ['eaddress', 'lat', 'lng'].indexOf(o)) {
  //       return null
  //     }
  //     return {
  //       Header: o as string,
  //       accessor: `${o}`
  //     }
  //   })
  //   .filter((o: any) => o !== null)
  // }, [data])

  return (
    <div className="DisplayListing p-4">
      <div className="text-xl font-semibold">
        <div className="text-gray-900">
          {!isLoadingList && data?.total ? (
            <>
              <span>
                {intl.formatMessage({
                  id: "listing.matchedcritera1",
                  defaultMessage: "...",
                })}
              </span>
              <b className="text-indigo-500">{data?.total}</b>
              <span>
                {intl.formatMessage({
                  id: "listing.matchedcritera2",
                  defaultMessage: "...",
                })}
              </span>
            </>
          ) : (
            <Skeleton height={30} />
          )}
        </div>
      </div>
      {!isLoadingList && data?.estates.length ? (
        data?.estates.map((o: any, idx: number) => {
          return (
            <ListingItem
              item={o}
              stations={data.stations}
              lines={data.stations}
              key={idx}
            ></ListingItem>
          );
        })
      ) : (
        <Skeleton wrapper={ListingItem} count={3} />
      )}
      <hr className="mb-8" />

      {/* <DataTable columns={columns1} data={data?.estates} />
    <hr className="mb-8" /> */}

      <Pagination
        perPage={size}
        setPage={setPage}
        setSize={setSize}
        pageCount={Math.ceil(data.total / size)}
        setIsLoadingList={setIsLoadingList}
      />
    </div>
  );
};

export default DisplayListing;
