import React, { useContext, useEffect, useState, useMemo } from "react";
import { DataType, DataContextType } from "../../estate-type";
import EstateContext from "../../estate-context";

import ListingItem from "../molecules/ListItem";

function TemplateSaved() {
  const {
    data,
    currentLine,
    currentStation,
    setCurrentLine,
    setCurrentStation,
    addToCart,
    cartItems,
    removeFromCart,
  } = useContext<DataContextType>(EstateContext);

  return (
    <>
      <h1 className="w-full p-4 text-2xl font-bold text-indigo-400">Saved</h1>

      {cartItems.map((o: any, idx: number) => {
        return (
          <ListingItem
            item={o}
            stations={data.stations}
            lines={data.stations}
            key={idx}
          ></ListingItem>
        );
      })}
    </>
  );
}

export default TemplateSaved;
