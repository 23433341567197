import React, { useContext, useEffect, useState, useMemo } from "react";
import { DataType, DataContextType } from "../../estate-type";

import EstateContext from "../../estate-context";

import ListingItem from "../molecules/ListItem";
import DataTable from "../molecules/DataTable";
import Pagination from "../molecules/Pagination";
import StationPicker from "../molecules/StationPicker";

const DistrictContainer = () => {
  const {
    data,
    currentLine,
    currentStation,
    setCurrentLine,
    setCurrentStation,
  } = useContext<DataContextType>(EstateContext);

  // const data1 = useMemo(
  //   () => data.estates,
  //   [data]
  // )

  // const columns1 = useMemo(
  //   () => Object.keys(data.estates[0] || []).map((o :string, idx: number) => {
  //     if (-1 !== ['eaddress', 'lat', 'lng'].indexOf(o)) {
  //       return null
  //     }
  //     return {
  //       Header: o as string,
  //       accessor: `${o}`
  //     }
  //   })
  //   .filter((o: any) => o !== null),
  //   [data]
  // )

  return (
    <div>
      <StationPicker />
      {/* <h2>Debug message</h2>
    <p>
      {
        currentLine + ' - ' + currentStation
      }
    </p> */}
    </div>
  );
};

export default DistrictContainer;
