import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { ItemType, ListingItemProps, UserCardProps } from "../../estate-type";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

export const UserCard = ({
  firstName,
}: // lastName,
// userName,
// contactNum,
UserCardProps) => {
  const intl = useIntl();

  return (
    <div className="UserCard p-4 flex text-left rounded-lg shadow-lg bg-white">
      <div className="w-1/5">
        <img
          className="w-16 h-16 rounded-full bg-gray-100"
          src="/avatar.png"
          alt=""
        />
      </div>
      <div className="w-4/5 grid grid-cols-2 gap-6">
        <p className="">
          {intl.formatMessage({
            id: "usercard.identity",
            defaultMessage: "...",
          })}
          ：{firstName}
        </p>
        {/* <p className="">{lastName}</p>
        <p className="">{userName}</p>
        <p className="">{contactNum}</p> */}
      </div>
    </div>
  );
};
