const lang = {
  "app.header": "編輯src / App.js並保存以重新加載。",
  "app.slogan": "落樓上車盤",
  "common.monetaryunit": "月租港幣",
  "common.goback": "返回",
  "common.station": "站",
  "common.tour.done": "完成",
  "common.tour.close": "關閉",
  "common.tour.next": "下一步",
  "common.tour.previous": "上一步",
  "nav.home": "首頁",
  "nav.saved": "已儲存",
  "nav.adminsubmission": "後台列表",
  "nav.useregister": "用戶註冊",
  "nav.login": "登入",
  "nav.unloginname": "未登入",
  "footer.contactus": "聯絡我們",
  "district.labelsort": "快選：",
  "district.labelline": "請選鐵路綫",
  "district.labelstation": "請選鐵路站",
  "pagination.prev": "上一頁",
  "pagination.next": "下一頁",
  "user.registerheading": "用戶注冊",
  "user.userid": "選擇您的用戶ID",
  "user.password": "密碼",
  "user.passwordchoose": "選擇您的用戶密碼",
  "user.passwordconfirm": "再次輸入密碼",
  "user.username": "顯示名稱",
  "user.email": "電郵地址",
  "user.mobile": "流動手提電話號碼",
  "user.image": "用戶頭像圖片",
  "user.registernow": "注冊用戶",
  "user.haveaccountlogin": "已注冊用戶按此登入",
  "user.loginheading": "用戶登入",
  "login.userid": "用戶ID",
  "login.password": "密碼",
  "login.login": "登入",
  "login.loginerror": "請檢查用戶ID及密碼再試一次",
  "listing.matchedcritera1": "共有",
  "listing.matchedcritera2": "項符合",
  "order.heading": "聯絡經紀/業主",
  "order.interesteditem": "有意項目 *",
  "order.inquirytext": "查詢內容 *",
  "order.additional_request": "業主追加查詢",
  "order.submitinquiry": "送出查詢",
  "estate.station": "車站",
  "estate.id": "ID",
  "estate.description": "描述",
  "estate.contactperson": "經紀/業主",
  "estate.geolocation": "地理坐標",
  "estate.distance": "距離車站",
  "estate.price": "價錢",
  "estate.contact": "聯絡經紀/業主",
  "estate.bookmark": " 加入書籤",
  "estatecard.spacious": "單位面積（實用）",
  "estatecard.built": "樓齡",
  "estatecard.year": "年",
  "estatecard.allowpets": "養寵物",
  "estatecard.allowsmoke": "吸煙",
  "estatecard.address": "地址: ",
  "modal.close": "關閉話框",
  "modal.title": "噢喔。。。",
  "usercard.identity": "代理",
  "home.tour.filter.heading": "快速找車站附近盤",
  "home.tour.filter.description": "先路線，後車站。",
  "home.tour.bookmark.heading": "書簽功能",
  "home.tour.bookmark.description": "想記下回頭再看就按下這個按鈕。",
  "home.tour.contact.heading": "聯絡代理",
  "home.tour.contact.description": "想了解多些不要等了！",
};

export default lang;
