import { UserType, StationType } from "../estate-type";
import HCaptcha from "@hcaptcha/react-hcaptcha";

export default HCaptcha;

export const BASEURL = `http://localhost:3000/data.json`;
export const APIURL = `${process.env.REACT_APP_APIURL}`;
export const OKSTR = "OK";
export const COOKIE_SESSION_NAME = `SessionID`;

export const lines = [
  {
    id: 3,
    cname: "東鐵綫",
    ename: "East Rail Line",
  },
  {
    id: 4,
    cname: "港島綫",
    ename: "Island Line",
  },
  {
    id: 5,
    cname: "觀塘綫",
    ename: "Kwun Tong Line",
  },
  {
    id: 6,
    cname: "屯馬綫",
    ename: "Tuen Ma Line",
  },
  {
    id: 7,
    cname: "東涌綫",
    ename: "Tung Chung Line",
  },
  {
    id: 8,
    cname: "將軍澳綫",
    ename: "Tseung Kwan O Line",
  },
  {
    id: 9,
    cname: "荃灣綫",
    ename: "Tsuen Wan Line",
  },
  {
    id: 10,
    cname: "南港島綫",
    ename: "South Island Line",
  },
  {
    id: 1,
    cname: "機場快綫",
    ename: "Airport Express Line",
  },
  {
    id: 2,
    cname: "迪士尼綫",
    ename: "Disneyland Resort Line",
  },
];

export const stations: Array<StationType> = [
  {
    id: 56,
    cname: "博覽館",
    ename: "AsiaWorld-Expo",
    line: 1,
    lat: "",
    lng: "",
  },
  {
    id: 47,
    cname: "機場",
    ename: "Airport",
    line: 1,
    lat: "",
    lng: "",
  },
  {
    id: 46,
    cname: "青衣",
    ename: "Tsing Yi",
    line: 1,
    lat: "",
    lng: "",
  },
  {
    id: 45,
    cname: "九龍",
    ename: "Kowloon",
    line: 1,
    lat: "",
    lng: "",
  },
  {
    id: 44,
    cname: "香港",
    ename: "Hong Kong",
    line: 1,
    lat: "",
    lng: "",
  },
  {
    id: 54,
    cname: "欣澳",
    ename: "Sunny Bay",
    line: 2,
    lat: "",
    lng: "",
  },
  {
    id: 55,
    cname: "迪士尼",
    ename: "Disneyland Resort",
    line: 2,
    lat: "",
    lng: "",
  },
  {
    id: 76,
    cname: "羅湖",
    ename: "Lo Wu",
    line: 3,
    lat: "",
    lng: "",
  },
  {
    id: 75,
    cname: "上水",
    ename: "Sheung Shui",
    line: 3,
    lat: "",
    lng: "",
  },
  {
    id: 74,
    cname: "粉嶺",
    ename: "Fanling",
    line: 3,
    lat: "",
    lng: "",
  },
  {
    id: 73,
    cname: "太和",
    ename: "Tai Wo",
    line: 3,
    lat: "",
    lng: "",
  },
  {
    id: 72,
    cname: "大埔墟",
    ename: "Tai Po Market",
    line: 3,
    lat: "",
    lng: "",
  },
  {
    id: 71,
    cname: "大學",
    ename: "University",
    line: 3,
    lat: "",
    lng: "",
  },
  {
    id: 69,
    cname: "火炭",
    ename: "Fo Tan",
    line: 3,
    lat: "",
    lng: "",
  },
  {
    id: 68,
    cname: "沙田",
    ename: "Sha Tin",
    line: 3,
    lat: "",
    lng: "",
  },
  {
    id: 67,
    cname: "大圍",
    ename: "Tai Wai",
    line: 3,
    lat: "",
    lng: "",
  },
  {
    id: 8,
    cname: "九龍塘",
    ename: "Kowloon Tong",
    line: 3,
    lat: "",
    lng: "",
  },
  {
    id: 65,
    cname: "旺角東",
    ename: "Mong Kok East",
    line: 3,
    lat: "",
    lng: "",
  },
  {
    id: 64,
    cname: "紅磡",
    ename: "Hung Hom",
    line: 3,
    lat: "",
    lng: "",
  },
  {
    id: 78,
    cname: "落馬洲",
    ename: "Lok Ma Chau",
    line: 3,
    lat: "",
    lng: "",
  },
  {
    id: 37,
    cname: "柴灣",
    ename: "Chai Wan",
    line: 4,
    lat: "",
    lng: "",
  },
  {
    id: 36,
    cname: "杏花邨",
    ename: "Heng Fa Chuen",
    line: 4,
    lat: "",
    lng: "",
  },
  {
    id: 35,
    cname: "筲箕灣",
    ename: "Shau Kei Wan",
    line: 4,
    lat: "",
    lng: "",
  },
  {
    id: 34,
    cname: "西灣河",
    ename: "Sai Wan Ho",
    line: 4,
    lat: "",
    lng: "",
  },
  {
    id: 33,
    cname: "太古",
    ename: "Tai Koo",
    line: 4,
    lat: "",
    lng: "",
  },
  {
    id: 32,
    cname: "鰂魚涌",
    ename: "Quarry Bay",
    line: 4,
    lat: "",
    lng: "",
  },
  {
    id: 31,
    cname: "北角",
    ename: "North Point",
    line: 4,
    lat: "",
    lng: "",
  },
  {
    id: 30,
    cname: "炮台山",
    ename: "Fortress Hill",
    line: 4,
    lat: "",
    lng: "",
  },
  {
    id: 29,
    cname: "天后",
    ename: "Tin Hau",
    line: 4,
    lat: "",
    lng: "",
  },
  {
    id: 28,
    cname: "銅鑼灣",
    ename: "Causeway Bay",
    line: 4,
    lat: "",
    lng: "",
  },
  {
    id: 27,
    cname: "灣仔",
    ename: "Wan Chai",
    line: 4,
    lat: "",
    lng: "",
  },
  {
    id: 2,
    cname: "金鐘",
    ename: "Admiralty",
    line: 4,
    lat: "",
    lng: "",
  },
  {
    id: 1,
    cname: "中環",
    ename: "Central",
    line: 4,
    lat: "",
    lng: "",
  },
  {
    id: 26,
    cname: "上環",
    ename: "Sheung Wan",
    line: 4,
    lat: "",
    lng: "",
  },
  {
    id: 81,
    cname: "西營盤",
    ename: "Sai Ying Pun",
    line: 4,
    lat: "",
    lng: "",
  },
  {
    id: 82,
    cname: "香港大學",
    ename: "HKU",
    line: 4,
    lat: "",
    lng: "",
  },
  {
    id: 83,
    cname: "堅尼地城",
    ename: "Kennedy Town",
    line: 4,
    lat: "",
    lng: "",
  },
  {
    id: 49,
    cname: "調景嶺",
    ename: "Tiu Keng Leng",
    line: 5,
    lat: "",
    lng: "",
  },
  {
    id: 48,
    cname: "油塘",
    ename: "Yau Tong",
    line: 5,
    lat: "",
    lng: "",
  },
  {
    id: 38,
    cname: "藍田",
    ename: "Lam Tin",
    line: 5,
    lat: "",
    lng: "",
  },
  {
    id: 15,
    cname: "觀塘",
    ename: "Kwun Tong",
    line: 5,
    lat: "",
    lng: "",
  },
  {
    id: 14,
    cname: "牛頭角",
    ename: "Ngau Tau Kok",
    line: 5,
    lat: "",
    lng: "",
  },
  {
    id: 13,
    cname: "九龍灣",
    ename: "Kowloon Bay",
    line: 5,
    lat: "22.3234",
    lng: "114.21406", // https://geodata.gov.hk/gs/api/v1.0.0/searchNearby?x=820487&y=840099&lang=en
  },
  {
    id: 12,
    cname: "彩虹",
    ename: "Choi Hung",
    line: 5,
    lat: "",
    lng: "",
  },
  {
    id: 11,
    cname: "鑽石山",
    ename: "Diamond Hill",
    line: 5,
    lat: "",
    lng: "",
  },
  {
    id: 10,
    cname: "黃大仙",
    ename: "Wong Tai Sin",
    line: 5,
    lat: "",
    lng: "",
  },
  {
    id: 9,
    cname: "樂富",
    ename: "Lok Fu",
    line: 5,
    lat: "",
    lng: "",
  },
  {
    id: 8,
    cname: "九龍塘",
    ename: "Kowloon Tong",
    line: 5,
    lat: "",
    lng: "",
  },
  {
    id: 7,
    cname: "石硤尾",
    ename: "Shek Kip Mei",
    line: 5,
    lat: "",
    lng: "",
  },
  {
    id: 16,
    cname: "太子",
    ename: "Prince Edward",
    line: 5,
    lat: "",
    lng: "",
  },
  {
    id: 6,
    cname: "旺角",
    ename: "Mong Kok",
    line: 5,
    lat: "",
    lng: "",
  },
  {
    id: 5,
    cname: "油麻地",
    ename: "Yau Ma Tei",
    line: 5,
    lat: "",
    lng: "",
  },
  {
    id: 84,
    cname: "何文田",
    ename: "Ho Man Tin",
    line: 5,
    lat: "",
    lng: "",
  },
  {
    id: 85,
    cname: "黃埔",
    ename: "Whampo",
    line: 5,
    lat: "",
    lng: "",
  },
  {
    id: 120,
    cname: "屯門",
    ename: "Tuen Mun",
    line: 6,
    lat: "",
    lng: "",
  },
  {
    id: 119,
    cname: "兆康",
    ename: "Siu Hong",
    line: 6,
    lat: "",
    lng: "",
  },
  {
    id: 118,
    cname: "天水圍",
    ename: "Tin Shui Wai",
    line: 6,
    lat: "",
    lng: "",
  },
  {
    id: 117,
    cname: "朗屏",
    ename: "Long Ping",
    line: 6,
    lat: "",
    lng: "",
  },
  {
    id: 116,
    cname: "元朗",
    ename: "Yuen Long",
    line: 6,
    lat: "",
    lng: "",
  },
  {
    id: 115,
    cname: "錦上路",
    ename: "Kam Sheung Road",
    line: 6,
    lat: "",
    lng: "",
  },
  {
    id: 114,
    cname: "荃灣西",
    ename: "Tsuen Wan West",
    line: 6,
    lat: "",
    lng: "",
  },
  {
    id: 20,
    cname: "美孚",
    ename: "Mei Foo",
    line: 6,
    lat: "",
    lng: "",
  },
  {
    id: 53,
    cname: "南昌",
    ename: "Nam Cheong",
    line: 6,
    lat: "",
    lng: "",
  },
  {
    id: 111,
    cname: "柯士甸",
    ename: "Austin",
    line: 6,
    lat: "",
    lng: "",
  },
  {
    id: 80,
    cname: "尖東",
    ename: "East Tsim Sha Tsui",
    line: 6,
    lat: "",
    lng: "",
  },
  {
    id: 64,
    cname: "紅磡",
    ename: "Hung Hom",
    line: 6,
    lat: "",
    lng: "",
  },
  {
    id: 84,
    cname: "何文田",
    ename: "Ho Man Tin",
    line: 6,
    lat: "",
    lng: "",
  },
  {
    id: 93,
    cname: "土瓜灣",
    ename: "To Kwa Wan",
    line: 6,
    lat: "",
    lng: "",
  },
  {
    id: 92,
    cname: "宋皇臺",
    ename: "Sung Wong Toi",
    line: 6,
    lat: "",
    lng: "",
  },
  {
    id: 91,
    cname: "啟德",
    ename: "Kai Tak",
    line: 6,
    lat: "",
    lng: "",
  },
  {
    id: 11,
    cname: "鑽石山",
    ename: "Diamond Hill",
    line: 6,
    lat: "",
    lng: "",
  },
  {
    id: 90,
    cname: "顯徑",
    ename: "Hin Keng",
    line: 6,
    lat: "",
    lng: "",
  },
  {
    id: 67,
    cname: "大圍",
    ename: "Tai Wai",
    line: 6,
    lat: "",
    lng: "",
  },
  {
    id: 96,
    cname: "車公廟",
    ename: "Che Kung Temple",
    line: 6,
    lat: "",
    lng: "",
  },
  {
    id: 97,
    cname: "沙田圍",
    ename: "Sha Tin Wai",
    line: 6,
    lat: "",
    lng: "",
  },
  {
    id: 98,
    cname: "第一城",
    ename: "City One",
    line: 6,
    lat: "",
    lng: "",
  },
  {
    id: 99,
    cname: "石門",
    ename: "Shek Mun",
    line: 6,
    lat: "",
    lng: "",
  },
  {
    id: 100,
    cname: "大水坑",
    ename: "Tai Shui Hang",
    line: 6,
    lat: "",
    lng: "",
  },
  {
    id: 101,
    cname: "恆安",
    ename: "Heng On",
    line: 6,
    lat: "",
    lng: "",
  },
  {
    id: 102,
    cname: "馬鞍山",
    ename: "Ma On Shan",
    line: 6,
    lat: "",
    lng: "",
  },
  {
    id: 103,
    cname: "烏溪沙",
    ename: "Wu Kai Sha",
    line: 6,
    lat: "",
    lng: "",
  },
  {
    id: 43,
    cname: "東涌",
    ename: "Tung Chung",
    line: 7,
    lat: "",
    lng: "",
  },
  {
    id: 54,
    cname: "欣澳",
    ename: "Sunny Bay",
    line: 7,
    lat: "",
    lng: "",
  },
  {
    id: 42,
    cname: "青衣",
    ename: "Tsing Yi",
    line: 7,
    lat: "",
    lng: "",
  },
  {
    id: 21,
    cname: "茘景",
    ename: "Lai King",
    line: 7,
    lat: "",
    lng: "",
  },
  {
    id: 53,
    cname: "南昌",
    ename: "Nam Cheong",
    line: 7,
    lat: "",
    lng: "",
  },
  {
    id: 41,
    cname: "奧運",
    ename: "Olympic",
    line: 7,
    lat: "",
    lng: "",
  },
  {
    id: 40,
    cname: "九龍",
    ename: "Kowloon",
    line: 7,
    lat: "",
    lng: "",
  },
  {
    id: 39,
    cname: "香港",
    ename: "Hong Kong",
    line: 7,
    lat: "",
    lng: "",
  },
  {
    id: 52,
    cname: "寶琳",
    ename: "Po Lam",
    line: 8,
    lat: "",
    lng: "",
  },
  {
    id: 51,
    cname: "坑口",
    ename: "Hang Hau",
    line: 8,
    lat: "",
    lng: "",
  },
  {
    id: 50,
    cname: "將軍澳",
    ename: "Tseung Kwan O",
    line: 8,
    lat: "",
    lng: "",
  },
  {
    id: 49,
    cname: "調景嶺",
    ename: "Tiu Keng Leng",
    line: 8,
    lat: "",
    lng: "",
  },
  {
    id: 48,
    cname: "油塘",
    ename: "Yau Tong",
    line: 8,
    lat: "",
    lng: "",
  },
  {
    id: 32,
    cname: "鰂魚涌",
    ename: "Quarry Bay",
    line: 8,
    lat: "",
    lng: "",
  },
  {
    id: 31,
    cname: "北角",
    ename: "North Point",
    line: 8,
    lat: "",
    lng: "",
  },
  {
    id: 57,
    cname: "康城",
    ename: "LOHAS Park",
    line: 8,
    lat: "",
    lng: "",
  },
  {
    id: 25,
    cname: "荃灣",
    ename: "Tsuen Wan",
    line: 9,
    lat: "",
    lng: "",
  },
  {
    id: 24,
    cname: "大窩口",
    ename: "Tai Wo Hau",
    line: 9,
    lat: "",
    lng: "",
  },
  {
    id: 23,
    cname: "葵興",
    ename: "Kwai Hing",
    line: 9,
    lat: "",
    lng: "",
  },
  {
    id: 22,
    cname: "葵芳",
    ename: "Kwai Fong",
    line: 9,
    lat: "",
    lng: "",
  },
  {
    id: 21,
    cname: "茘景",
    ename: "Lai King",
    line: 9,
    lat: "",
    lng: "",
  },
  {
    id: 20,
    cname: "美孚",
    ename: "Mei Foo",
    line: 9,
    lat: "",
    lng: "",
  },
  {
    id: 19,
    cname: "茘枝角",
    ename: "Lai Chi Kok",
    line: 9,
    lat: "",
    lng: "",
  },
  {
    id: 18,
    cname: "長沙灣",
    ename: "Cheung Sha Wan",
    line: 9,
    lat: "",
    lng: "",
  },
  {
    id: 17,
    cname: "深水埗",
    ename: "Sham Shui Po",
    line: 9,
    lat: "",
    lng: "",
  },
  {
    id: 16,
    cname: "太子",
    ename: "Prince Edward",
    line: 9,
    lat: "",
    lng: "",
  },
  {
    id: 6,
    cname: "旺角",
    ename: "Mong Kok",
    line: 9,
    lat: "",
    lng: "",
  },
  {
    id: 5,
    cname: "油麻地",
    ename: "Yau Ma Tei",
    line: 9,
    lat: "",
    lng: "",
  },
  {
    id: 4,
    cname: "佐敦",
    ename: "Jordan",
    line: 9,
    lat: "",
    lng: "",
  },
  {
    id: 3,
    cname: "尖沙咀",
    ename: "Tsim Sha Tsui",
    line: 9,
    lat: "",
    lng: "",
  },
  {
    id: 2,
    cname: "金鐘",
    ename: "Admiralty",
    line: 9,
    lat: "",
    lng: "",
  },
  {
    id: 1,
    cname: "中環",
    ename: "Central",
    line: 9,
    lat: "",
    lng: "",
  },
  {
    id: 89,
    cname: "海怡半島",
    ename: "South Horizons",
    line: 10,
    lat: "",
    lng: "",
  },
  {
    id: 88,
    cname: "利東",
    ename: "Lei Tung",
    line: 10,
    lat: "",
    lng: "",
  },
  {
    id: 87,
    cname: "黃竹坑",
    ename: "Wong Chuk Hang",
    line: 10,
    lat: "",
    lng: "",
  },
  {
    id: 86,
    cname: "海洋公園",
    ename: "Ocean Park",
    line: 10,
    lat: "",
    lng: "",
  },
  {
    id: 2,
    cname: "金鐘",
    ename: "Admiralty",
    line: 10,
    lat: "",
    lng: "",
  },
];

export function handleApiError(e: any) {
  console.log(`API error: ${e}`);
  return {};
}

/**
 * API
 * TODO: convert api call into a Class object
 */
export class RentantApi {
  name: string;
  readonly baseurl: string = APIURL;
  res: any;
  constructor() {
    this.name = "Polygon";
  }

  async fetchAPI(method: string, body: string) {
    try {
      this.res = await fetch(this.baseurl, {
        method: "POST",
        redirect: "follow",
        // credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          row_data: "1",
          type: "insert_generic",
          fields: ["estate_id", "userid", "addtime", "date", "meta"],
          default_value: [
            "",
            "",
            "tsToDatetime(timestamp(true))",
            "tsToDatetime(timestamp(true))",
            "{}",
          ],
          data: {
            estate_id: ``,
            userid: ``,
            addtime: "tsToDatetime(timestamp(true))",
            date: "tsToDatetime(timestamp(true))",
            meta: JSON.stringify({
              msg: ``,
            }),
          },
          action: "insert_order",
          auth_fields: ["userid", "password"],
          auth_data: {
            // TODO: remove client side password
            userid: `${process.env.REACT_APP_USER_ID}`,
            password: `${process.env.REACT_APP_USER_PASSWORD}`,
          },
          // TODO: potential hazard, should use Cookies in headers
          SessionID: "".split("=")[1],
        }),
      });
    } catch (error: any) {
      // Error handling
      handleApiError(`[apiQueryGetEstate]\n${error}`);
    }
  }
}

export async function listEstate(page: number, size: number) {
  console.log("call listEstate");
  try {
    let res = await fetch(APIURL, {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        row_data: "1",
        type: "query_generic",
        data: {
          "Exchange Status": "on self",
          meta: "{}",
        },
        sortby: [
          {
            fieldname: "addtime", // then do this sort, more important fields
            optr: "ASC",
          },
          {
            fieldname: "Distance", // do this sort first
            optr: "ASC",
          },
        ],
        offset: page * size,
        limit: size,
        action: "query_estate",
        auth_fields: ["userid", "password"],
        auth_data: {
          userid: `${process.env.REACT_APP_USER_ID}`,
          password: `${process.env.REACT_APP_USER_PASSWORD}`,
        },
      }),
    });

    let result = await res.json();
    return result;
  } catch (error) {
    // Error handling
    handleApiError(`[listEstate]\n${error}`);
  }
}

export async function apiQueryGetEstate(
  page: number,
  size: number,
  station: number
) {
  try {
    let estates: any[] = [];
    let res: any = await fetch(
      `${APIURL}&type=query&offset=${
        page * size
      }&pagesize=${size}&station=${station}`
    );
    let ajson = await res.json();

    // console.log(`[API]apiQueryGetEstate `, ajson)

    if (ajson?.status === "SUCCESS") {
      estates = await ajson?.result;
    }

    return {
      estates: estates || [],
      total: ajson?.size || 0,
      stations: stations,
      lines: lines,
    };
  } catch (error) {
    // Error handling
    handleApiError(`[apiQueryGetEstate]\n${error}`);
  }
}

export async function apiQueryOneEstate(estate_id: number) {
  try {
    let estates: any[] = [];
    let res: any = await fetch(`${APIURL}&type=estate&estate_id=${estate_id}`);
    let ajson = await res.json();

    // console.log(`[API]apiQueryOneEstate `, ajson)

    if (ajson?.status === "SUCCESS") {
      estates = await ajson?.result;
    }

    return {
      estates: estates || [],
      total: ajson?.size || 0,
      stations: stations,
      lines: lines,
    };
  } catch (error) {
    // Error handling
    handleApiError(`[apiQueryOneEstate]\n${error}`);
  }
}

export async function addOrder(
  estate_id: number,
  userid: number,
  msg: any,
  cookies: any
) {
  // let res = await fetch(`${APIURL}`, {
  //   method: 'POST',
  //   redirect: 'follow',
  //   headers: {
  //     'Content-Type': 'application/json'
  //   },
  //   body: JSON.stringify({
  //     "row_data": "1",
  //     "type": "insert_generic",
  //     "fields": [
  //       "estate_id",
  //       "userid",
  //       "addtime",
  //       "date",
  //       "meta"
  //     ],
  //     "default_value": [
  //       "",
  //       "",
  //       "tsToDatetime(timestamp(true))",
  //       "tsToDatetime(timestamp(true))",
  //       "{}"
  //     ],
  //     "data": {
  //       "estate_id": estate_id,
  //       "userid": userid,
  //       "addtime": "tsToDatetime(timestamp(true))",
  //       "date": "tsToDatetime(timestamp(true))",
  //       "meta": JSON.stringify({
  //         msg: msg
  //       })
  //     },
  //     "action": "insert_order",
  //     "auth_fields": ["userid", "password"],
  //     "auth_data": {
  //       "userid": `${process.env.REACT_APP_USER_ID}`,
  //       "password": `${process.env.REACT_APP_USER_PASSWORD}`
  //     }
  //   })
  // })
  try {
    var res = await fetch(APIURL, {
      method: "POST",
      redirect: "follow",
      // credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        row_data: "1",
        type: "insert_generic",
        fields: ["estate_id", "userid", "addtime", "date", "meta"],
        default_value: [
          "",
          "",
          "tsToDatetime(timestamp(true))",
          "tsToDatetime(timestamp(true))",
          "{}",
        ],
        data: {
          estate_id: `${estate_id}`,
          userid: `${userid}`,
          addtime: "tsToDatetime(timestamp(true))",
          date: "tsToDatetime(timestamp(true))",
          meta: JSON.stringify({
            msg: `${msg}`,
          }),
        },
        action: "insert_order",
        auth_fields: ["userid", "password"],
        auth_data: {
          // TODO: remove client side password
          userid: `${process.env.REACT_APP_USER_ID}`,
          password: `${process.env.REACT_APP_USER_PASSWORD}`,
        },
        // TODO: potential hazard, should use Cookies in headers
        SessionID: ("" + cookies[COOKIE_SESSION_NAME]).split("=")[1],
      }),
    });

    let ajson = await res.json();
    // console.log(`addOrder `, ajson)
    console.log(
      "addOrder " +
        JSON.stringify(ajson) +
        "\n\n" +
        ("" + cookies[COOKIE_SESSION_NAME]).split("=")[1]
    );
  } catch (error) {
    // Error handling
    handleApiError(`[addOrder]\n${error}`);
  }
}

export async function apiAddUser(newItem: UserType) {
  try {
    var res = await fetch(`${APIURL}`, {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        type: "user_opt",
        row_data: "1",
        user_opt: "CREATE",
        userid: `${newItem.userid}`,
        password: `${newItem.password}`,
        additionalFields: `{"schema":["username","role","email","tel","activated","meta"],"data":{"username":"${
          newItem.username
        }","role":"rentant","email":"${newItem.email}","tel":"${
          newItem.mobile
        }","activated":"0","meta":"${JSON.stringify(newItem.meta)}"}}`,
        captchaToken: `${newItem.captchaToken}`,
      }),
    });

    let ajson = await res.json();
    console.log(`apiAddUser `, ajson);
    return ajson.status;
  } catch (error) {
    // Error handling
    handleApiError(`[apiAddUser]\n${error}`);
  }
}

export async function apiUserLogin(newItem: UserType) {
  try {
    var res = await fetch(`${APIURL}`, {
      method: "POST",
      redirect: "follow",
      // credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        type: "user_opt",
        row_data: "1",
        user_opt: "LOGIN",
        userid: `${newItem.userid}`,
        password: `${newItem.password}`,
        additionalFields: `{}`,
      }),
    });

    let ajson = await res.json();
    console.log(`apiUserLogin `, ajson);
    return {
      status: ajson.status,
      sessionid: ajson.session,
      userid: ajson.userid,
      username: ajson.username,
      email: ajson.email,
      mobile: ajson.mobile,
      meta: ajson.meta,
      role: ajson.role,
    };
  } catch (error) {
    // Error handling
    handleApiError(`[apiUserLogin]\n${error}`);
  }
}
