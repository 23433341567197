import React, { useContext, useEffect, useState } from "react";
import { AnswerContainerProps } from "../../estate-type";

export const AnswerContainer = ({ id, text, setAns }: AnswerContainerProps) => {
  const [answer, setAnswer] = useState("");

  return (
    <>
      <p className="text-bold">{text}</p>
      <p>
        <textarea
          id={id}
          autoComplete="false"
          tabIndex={0}
          className="py-1 px-1 outline-none block h-full w-full"
          value={answer}
          onChange={(e) => {
            setAns(e.target.value);
            setAnswer(e.target.value);
          }}
        />
      </p>
    </>
  );
};
