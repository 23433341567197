import React, { useContext, useEffect, useState } from "react";
import { ImageContainerProps } from "../../estate-type";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

export const ImageContainer = ({ src, alt }: ImageContainerProps) => {
  const imagePath = "/" + src;

  return (
    <a className="ImageContainer " href={imagePath}>
      <div className="mr-2 text-center shadow-lg bg-white">
        <div className="min-w-min">
          <img
            className="mx-auto h-40 rounded bg-gray-100"
            src={imagePath}
            alt={alt}
          />
        </div>
      </div>
    </a>
  );
};
