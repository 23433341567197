import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

import { DataContextType, NavBarProps } from "../../estate-type";
import EstateContext from "../../estate-context";

import { ProfileBadge } from "./ProfileBadge";

const Navbar = ({ lang, setLang }: NavBarProps) => {
  const intl = useIntl();

  const { data, contextLang, setContextLang } =
    useContext<DataContextType>(EstateContext);

  const [navOpen, setNavOpen] = useState(false);

  useEffect(() => {
    document
      .querySelectorAll("main")[0]
      .addEventListener("click", closeSidemenu);
    return function cleanup() {
      window.removeEventListener("click", closeSidemenu);
    };
  }, []);

  let closeSidemenu = () => {
    setNavOpen(false);
  };

  return (
    <nav className="dark:bg-gray-800">
      <div className="container px-4 py-3 mx-auto md:flex md:justify-between md:items-center">
        <div className="flex items-center justify-between">
          <div className="flex">
            <Link
              to="/"
              className="text-xl font-bold text-gray-800 dark:text-white md:text-2xl hover:text-gray-700 dark:hover:text-gray-300"
              href="#"
            >
              <img
                className="h-8 md:h-12"
                src="/brand.png"
                height="60"
                alt="Rentant"
              />
            </Link>

            <h1 className="inline-block md:hidden mx-2 text-2xl font-bold text-indigo-400">
              {intl.formatMessage({
                id: "app.slogan",
                description: "Countdown HomeScreen",
                defaultMessage: "...",
                // values: {n: time},
              })}
            </h1>
          </div>

          {/* <!-- Mobile menu button --> */}
          <div className="flex md:hidden">
            <button
              type="button"
              className="z-50 fixed top-4 right-4 md:relative md:top-auto md:right-auto text-gray-500 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400 focus:outline-none focus:text-gray-600 dark:focus:text-gray-400"
              aria-label="toggle menu"
              onClick={() => setNavOpen(!navOpen)}
            >
              {navOpen ? (
                <>X</>
              ) : (
                <svg viewBox="0 0 24 24" className="w-6 h-6 fill-current">
                  <path
                    fillRule="evenodd"
                    d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                  ></path>
                </svg>
              )}
            </button>
          </div>
        </div>

        {/* <!-- Mobile Menu open: "block", Menu closed: "hidden" --> */}
        <div
          className={
            "z-40 fixed top-0 left-0 md:relative md:top-auto md:left-auto px-4 pt-8 pb-8 md:p-0 bg-gray-100 items-center shadow-lg md:shadow-none w-full md:w-auto md:flex md:flex-row " +
            (navOpen ? "sm:flex-col" : "hidden")
          }
        >
          <div className="flex flex-col md:flex-row md:mx-6">
            <Link
              onClick={() => setNavOpen(!navOpen)}
              to="/"
              className="my-2 text-gray-700 dark:text-gray-200 hover:text-indigo-500 dark:hover:text-indigo-400 md:mx-4 md:my-0"
            >
              {intl.formatMessage({
                id: "nav.home",
                defaultMessage: "...",
              })}
            </Link>
            <Link
              onClick={() => setNavOpen(!navOpen)}
              to="/saved"
              className="my-2 text-gray-700 dark:text-gray-200 hover:text-indigo-500 dark:hover:text-indigo-400 md:mx-4 md:my-0"
            >
              {intl.formatMessage({
                id: "nav.saved",
                defaultMessage: "...",
              })}
            </Link>
            {/* <Link
              onClick={() => setNavOpen(!navOpen)}
              to="/adminSubmission"
              className="my-2 text-gray-700 dark:text-gray-200 hover:text-indigo-500 dark:hover:text-indigo-400 md:mx-4 md:my-0"
            >
              {intl.formatMessage({
                id: "nav.adminsubmission",
                defaultMessage: "...",
              })}
            </Link> */}

            <Link
              onClick={() => setNavOpen(!navOpen)}
              to="/userregister"
              className="my-2 text-gray-700 dark:text-gray-200 hover:text-indigo-500 dark:hover:text-indigo-400 md:mx-4 md:my-0"
            >
              {intl.formatMessage({
                id: "nav.useregister",
                defaultMessage: "...",
              })}
            </Link>

            <Link
              onClick={() => setNavOpen(!navOpen)}
              to="/login"
              className="my-2 text-gray-700 dark:text-gray-200 hover:text-indigo-500 dark:hover:text-indigo-400 md:mx-4 md:my-0"
            >
              {intl.formatMessage({
                id: "nav.login",
                defaultMessage: "...",
              })}
            </Link>

            {/* <a
              className="my-2 text-gray-700 dark:text-gray-200 hover:text-indigo-500 dark:hover:text-indigo-400 md:mx-4 md:my-0"
              href="#"
              >Shop</a>
            <a
              className="my-2 text-gray-700 dark:text-gray-200 hover:text-indigo-500 dark:hover:text-indigo-400 md:mx-4 md:my-0"
              href="#"
              >Contact</a>
            <a
              className="my-2 text-gray-700 dark:text-gray-200 hover:text-indigo-500 dark:hover:text-indigo-400 md:mx-4 md:my-0"
              href="#"
              >About</a> */}
          </div>

          <div className="flex md:block">
            <a
              className="relative text-gray-700 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-300"
              href="#"
            >
              <ProfileBadge />
            </a>
          </div>

          <select
            value={lang}
            onChange={(e) => {
              localStorage.setItem("locale", e.target.value);
              setContextLang(e.target.value);
              setLang(e.target.value);
            }}
          >
            <option value="en">English</option>
            <option value="zh-hk">中文</option>
          </select>

          {/* <button>
            {
              'hi ' + intl.formatMessage({
                id: "app.header",
                description: 'Countdown HomeScreen',
                defaultMessage: 'Unlocked in {n} hours',
                // values: {n: time},
              })
            }
          </button>

          <FormattedMessage
            id = "app.header"
            defaultMessage="Tutorial brought to you by Lokalise"
            values = {{blogName: "Lokalise"}}
          /> */}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
