import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { DataContextType, EstateCardProps } from "../../estate-type";
import EstateContext from "../../estate-context";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

export const ProfileBadge = () => {
  const intl = useIntl();

  const { data, contextLang, setContextLang, currentUser } =
    useContext<DataContextType>(EstateContext);

  return (
    <div className="inline-block text-left">
      <FontAwesomeIcon icon={faUser} />
      <span className="absolute top-0 left-0 p-1 text-xs text-white bg-indigo-500 rounded-full"></span>

      <p className="inline-block overflow-ellipsis md:hidden">
        {
          // TODO: use cookies user
          currentUser?.username ||
            intl.formatMessage({
              id: "nav.unloginname",
              defaultMessage: "...",
            })
        }
      </p>
    </div>
  );
};
