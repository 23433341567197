import * as en from "./en";
import * as zh_hk from "./zh-hk";

type listLangType = {
  [key: string]: Record<string, any>;
};

const langmap: listLangType = {
  en: en.default,
  "zh-hk": zh_hk.default,
};

export default langmap;
