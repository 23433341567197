import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { ItemType, ListingItemProps, DataContextType } from "../../estate-type";

import EstateContext from "../../estate-context";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark } from "@fortawesome/free-solid-svg-icons";

const ListingItem = ({ children, item, stations, lines }: ListingItemProps) => {
  const intl = useIntl();

  const {
    data,
    currentLine,
    currentStation,
    setCurrentLine,
    setCurrentStation,
    contextLang,
    addToCart,
    cartItems,
    removeFromCart,
  } = useContext<DataContextType>(EstateContext);

  let isCarted = cartItems.filter(
    (o: any, idx: number) => o.estate_id === item?.estate_id
  ).length;

  return (
    <div className="flex flex-col justify-between mb-4 pt-4 border-t-2">
      <div className="Heading flex justify-between relative z-0">
        <h1
          className="text-xl font-bold relative z-10"
          data-estate_id={item?.estate_id}
        >
          {item?.title}
        </h1>
        <div
          className="Corner w-20 h-20 absolute z-0 top-0 right-0"
          style={{
            background:
              "linear-gradient(45deg, hsla(0, 0%, 0%, 0) 0%, hsla(0, 0%, 0%, 0) 49.5%, rgba(224, 231, 255, 1) 50.0%, rgba(224, 231, 255, 1) 100%)",
          }}
        ></div>
        <span className="w-20 p-2 text-sm text-right  relative z-10">
          {/* {intl.formatMessage({
            id: "estate.station",
            defaultMessage: "...",
          })}
          :{" "} */}
          {stations &&
            stations
              .filter((o) => o.id === item?.station)
              .slice(0, 1)
              .map((o: any, idx: number) => (
                <span key={idx}>
                  {contextLang === process.env.REACT_APP_DEFAULT_LANG
                    ? o.cname
                    : o.ename}
                </span>
              ))}
        </span>
      </div>

      <div className="flex flex-col md:flex-row  relative z-10">
        <div className="flex flex-col w-full md:w-1/2">
          <p className="">
            <span>
              {contextLang === process.env.REACT_APP_DEFAULT_LANG
                ? item?.caddress
                : item?.eaddress}
            </span>
          </p>
          <hr />
          {/* <p>
            {intl.formatMessage({
              id: "estate.id",
              defaultMessage: "...",
            })}
            : {item?.estate_id}
          </p> */}
          <p className="mb-2">
            {/* <span>{intl.formatMessage({
              id: "estate.description",
              defaultMessage: "...",
            })}
            : </span> */}
            {item?.description}
          </p>
          {/* <p>
            {intl.formatMessage({
              id: "estate.contactperson",
              defaultMessage: "...",
            })}
            :{" "}
            {
              // TODO: load agent username
              item?.username
            }
          </p> */}
          {/* <p>
            {
            // TODO: show the distance
            intl.formatMessage({
              id: "estate.geolocation",
              defaultMessage: "...",
            })}
            : {item?.lat}, {item?.lng}
          </p> */}
          <p>
            {
              // TODO: show the distance
              intl.formatMessage({
                id: "estate.distance",
                defaultMessage: "...",
              })
            }
            : {item?.distance}
          </p>
          <p>
            <span>
              {intl.formatMessage({
                id: "estate.price",
                defaultMessage: "...",
              })}
              :{" "}
            </span>
            <span className="">
              {
                // TODO: add DB fields about sales or lease
                intl.formatMessage({
                  id: "common.monetaryunit",
                  defaultMessage: "...",
                })
              }
            </span>
            <span className="text-red-600">{item?.price_range}</span>
          </p>

          {item?.estate_id ? (
            <div className="ActionArea mt-4">
              <Link
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                to={`/submit/${item?.estate_id}`}
                className="gContact mr-2 p-2 leading-5 appearance-none hover:bg-green-300 rounded-md text-gray-100 bg-blue-600 hover:shadow-inner hover:bg-blue-700 transition-all duration-300 "
              >
                {intl.formatMessage({
                  id: "estate.contact",
                  defaultMessage: "...",
                })}
              </Link>
              <button
                className={
                  "gBookmark mx-2 p-2 leading-5 appearance-none rounded-md transition-all duration-300 " +
                  (isCarted
                    ? "text-indigo-600 hover:text-white bg-white hover:bg-indigo-300"
                    : "text-white bg-indigo-500 hover:bg-indigo-700")
                }
                onClick={(e) => {
                  if (isCarted) {
                    removeFromCart(item?.estate_id);
                  } else {
                    addToCart(item);
                  }
                }}
              >
                <FontAwesomeIcon icon={faBookmark} />
                {intl.formatMessage({
                  id: "estate.bookmark",
                  defaultMessage: "...",
                })}
              </button>
            </div>
          ) : null}
        </div>

        {item ? (
          <div className="ImageContainer w-full md:w-1/2 md:ml-4 mt-4 md:mt-0">
            {item?.image_link
              ? JSON.parse(item?.image_link)
                  .slice(0, 1)
                  .map((o: any, idx: number) => {
                    return (
                      <img
                        className="h-auto p-2 block bg-white"
                        src={o}
                        alt={"" + item?.estate_id}
                        key={idx}
                      />
                    );
                  })
              : null}
          </div>
        ) : (
          <Skeleton height={50} />
        )}
      </div>
    </div>
  );
};

export default ListingItem;
