import React, { useContext, useEffect, useState } from "react";
import { PaginationProps } from "../../estate-type";
import { FormattedMessage, useIntl } from "react-intl";

import ReactPaginate from "react-paginate";

const Pagination = ({
  perPage,
  pageCount,
  setPage,
  setSize,
  setIsLoadingList,
}: PaginationProps) => {
  const intl = useIntl();

  return (
    <div className="">
      <ReactPaginate
        previousLabel={intl.formatMessage({
          id: "pagination.prev",
          defaultMessage: "&lt;",
        })}
        nextLabel={intl.formatMessage({
          id: "pagination.next",
          defaultMessage: "&gt;",
        })}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={pageCount}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        onPageChange={(data: any) => {
          let selected = data.selected;
          let offset = Math.ceil(selected * perPage);
          window.scrollTo(0, 0);
          console.log(`load page: ${selected + 1} `);
          setIsLoadingList(true);
          setPage(selected);
        }}
        containerClassName={"pagination"}
        activeClassName={"active text-white bg-indigo-600"}
        pageClassName={"cursor-pointer"}
      />
    </div>
  );
};

export default Pagination;
