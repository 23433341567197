import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import { FormattedMessage, IntlProvider } from "react-intl";
import { CookiesProvider } from "react-cookie";

import { DataContextType } from "./estate-type";
import { EstateContextProvider } from "./estate-context";
import langmap from "./i18n/lang";

import Navbar from "./components/molecules/Navbar";
import TemplateHome from "./components/templates/TemplateHome";
import TemplateSaved from "./components/templates/TemplateSaved";
import TemplateForm from "./components/templates/TemplateForm";
import TemplateSubmitted from "./components/templates/TemplateSubmitted";
import TemplateAdminSubmission from "./components/templates/TemplateAdminSubmission";
import TemplateUserRegister from "./components/templates/TemplateUserRegister";
import TemplateUserLogin from "./components/templates/TemplateUserLogin";
import { Footer } from "./components/molecules/Footer";

import "./App.css";

function App() {
  const DEFAULT_LANG = process.env.REACT_APP_DEFAULT_LANG || "zh-hk";
  const [lang, setLang] = useState(
    localStorage.getItem("locale") || DEFAULT_LANG
  );
  const [locale, setLocale] = useState<Record<string, any>>(
    langmap[DEFAULT_LANG]
  );

  useEffect(() => {
    setLocale(langmap[lang]);
  }, [lang]);

  return (
    <CookiesProvider>
      <IntlProvider
        messages={locale}
        locale={lang}
        defaultLocale={process.env.REACT_APP_DEFAULT_LANG}
      >
        <EstateContextProvider>
          <Router>
            <div className="App">
              <header className="App-header">
                <Navbar lang={lang} setLang={setLang} />
              </header>

              <main>
                <Switch>
                  <Route exact path="/">
                    <TemplateHome />
                  </Route>

                  {/* <Route path="/:station/:size/:page">
              <TemplateHome />
            </Route> */}

                  <Route path="/saved">
                    <TemplateSaved />
                  </Route>

                  <Route path="/submit/:id">
                    <TemplateForm />
                  </Route>

                  <Route exact path="/submitsuccess">
                    <TemplateSubmitted />
                  </Route>

                  <Route exact path="/userregister">
                    <TemplateUserRegister />
                  </Route>

                  <Route exact path="/user-register-success">
                    <h1>Good</h1>
                  </Route>

                  <Route exact path="/login">
                    <TemplateUserLogin />
                  </Route>

                  <Route exact path="/contactus">
                    <p>Contact Us</p>
                  </Route>

                  <Route path="/adminSubmission">
                    <TemplateAdminSubmission />
                  </Route>
                </Switch>
              </main>

              <Footer />
            </div>
          </Router>
        </EstateContextProvider>
      </IntlProvider>
    </CookiesProvider>
  );
}

export default App;
