import React, { useContext, useEffect, useState, useMemo } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { useCookies } from "react-cookie";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { OKSTR, COOKIE_SESSION_NAME } from "../../api/api";
import { DataContextType, UserType } from "../../estate-type";
import EstateContext from "../../estate-context";

import { DebugTableWeb } from "../organisms/DebugTableWeb";

interface LoginForm {
  userid: string;
  password: string;
}

interface ParamTypes {
  id: string;
  name: string;
}

const schema = yup
  .object({
    userid: yup.string().required(),
    password: yup.string().min(6).required(),
  })
  .required();

function TemplateUserLogin() {
  const intl = useIntl();
  const { data, currentUser, setCurrentUser, addUser, apiUserLogin } =
    useContext<DataContextType>(EstateContext);

  let history = useHistory();
  const params = useParams<ParamTypes>();
  const { id } = params;
  const [cookies, setCookie] = useCookies([COOKIE_SESSION_NAME, "userid"]);

  const [meta, setMeta] = useState({});
  const [isError, setIsError] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginForm>({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data: LoginForm) => {
    console.log(`User Login Form `, data);
    // TODO: change to check userid and password
    let status: any = await apiUserLogin(data);

    let tomorrow = new Date();
    tomorrow.setUTCDate(tomorrow.getUTCDate() + 3);
    // console.log(tomorrow); // Local time
    setCookie(COOKIE_SESSION_NAME, `${status.sessionid}`, {
      path: "/",
      expires: tomorrow, // UTC time
    });
    setCookie("userid", `${status.userid}`, {
      path: "/",
      expires: tomorrow, // UTC time
    });

    setCurrentUser({
      userid: `${status.userid}`,
      password: "",
      username: `${status.username}`,
      email: `${status.email}`,
      mobile: `${status.mobile}`,
      meta: `${status.meta}`,
      passwordconfirm: "",
      captchaToken: "",
      role: `${status.role}`,
    });

    console.log(status);

    if (OKSTR === status.status) {
      setIsError(false);
      history.push("/");
    } else {
      setIsError(true);
      console.error(`User login: Error`, status, status.sessionid);
    }
  };

  return (
    <>
      <h1 className="w-full p-4 text-2xl font-bold text-indigo-400">
        {intl.formatMessage({
          id: "user.loginheading",
          defaultMessage: "...",
        })}
      </h1>

      <div className="container mx-auto">
        <div className="flex justify-center px-6 my-12">
          <div className="w-full xl:w-3/4 lg:w-11/12 flex">
            <form
              className="mx-auto bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col"
              onSubmit={handleSubmit(onSubmit)}
            >
              {cookies[COOKIE_SESSION_NAME] && (
                <h1 data-cookie={cookies[COOKIE_SESSION_NAME]}>Hello!</h1>
              )}

              <div className="mb-4">
                <label
                  className="block text-grey-darker text-sm font-bold mb-2"
                  htmlFor="username"
                >
                  {intl.formatMessage({
                    id: "login.userid",
                    defaultMessage: "...",
                  })}
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
                  id="userid"
                  type="text"
                  placeholder=""
                  {...register("userid")}
                />
              </div>
              <div className="mb-6">
                <label
                  className="block text-grey-darker text-sm font-bold mb-2"
                  htmlFor="password"
                >
                  {intl.formatMessage({
                    id: "login.password",
                    defaultMessage: "...",
                  })}
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker mb-3"
                  id="password"
                  type="password"
                  placeholder="******************"
                  {...register("password")}
                />
                <p>{errors.password?.message}</p>
              </div>
              <div className="flex items-center justify-between">
                <button
                  className="bg-indigo-500 hover:bg-blue-dark text-white font-bold py-2 px-4 rounded"
                  type="submit"
                >
                  {intl.formatMessage({
                    id: "login.login",
                    defaultMessage: "...",
                  })}
                </button>
                {/* <a className="inline-block align-baseline font-bold text-sm text-blue hover:text-blue-darker" href="#">
                Forgot Password?
              </a> */}
              </div>

              {isError ? (
                <p className="text-red-600">
                  {intl.formatMessage({
                    id: "login.loginerror",
                    defaultMessage: "...",
                  })}
                </p>
              ) : null}

              <div className="text-center">
                <Link
                  to="/userregister"
                  className="inline-block text-sm text-blue-500 align-baseline hover:text-blue-800"
                  href="/userregister"
                >
                  {intl.formatMessage({
                    id: "user.registernow",
                    defaultMessage: "...",
                  })}
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* <DebugTableWeb vars={{
        'items': [
          {
            key: 'router params',
            value: params,
            type: 'JSON'
          },
          {
            key: 'inquiryText',
            value: inquiryText,
            type: 'string'
          }
        ]
      }} /> */}
    </>
  );
}

export default TemplateUserLogin;
