import React, { useContext, useEffect, useState, useMemo } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { default as RenamedCaptcha, OKSTR } from "../../api/api";
import { DataContextType, UserType } from "../../estate-type";
import EstateContext from "../../estate-context";

import { DebugTableWeb } from "../organisms/DebugTableWeb";
import { Modal } from "../molecules/Modal";

interface ParamTypes {
  id: string;
  name: string;
}

const schema = yup
  .object({
    userid: yup.string().required(),
    username: yup.string().required(),
    password: yup.string().min(6).required(),
    email: yup.string().email(),
    mobile: yup
      .number()
      .positive()
      .integer()
      .min(10000000)
      .max(99999999)
      .required(),
    passwordconfirm: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match"),
    captchaToken: yup.string(),
  })
  .required();

function TemplateUserRegister() {
  const intl = useIntl();
  const { data, currentUser, addUser } =
    useContext<DataContextType>(EstateContext);

  let history = useHistory();
  const params = useParams<ParamTypes>();
  const { id } = params;

  const [meta, setMeta] = useState({});
  const [hasError, setHasError] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UserType>({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data: UserType) => {
    data["captchaToken"] = captchaToken;
    console.log(`User Reg Form `, data);
    let status: any = await addUser(data);
    if (OKSTR === status) {
      history.push("/user-register-success");
    } else {
      // TODO: pop modal to notify user the registration failed
      setHasError(true);
      console.log(`[Error] addUser: check userid`, status);
    }
  };

  return (
    <>
      <h1 className="w-full p-4 text-2xl font-bold text-indigo-400">
        {intl.formatMessage({
          id: "user.registerheading",
          defaultMessage: "...",
        })}
      </h1>

      <div className="container mx-auto">
        <div className="flex justify-center px-6 my-12">
          <div className="w-full xl:w-3/4 lg:w-11/12 flex">
            <div className="w-full bg-white p-5 rounded-lg lg:rounded-l-none">
              <form
                className="px-8 pt-6 pb-8 mb-4 bg-white rounded"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="mb-4 md:flex md:justify-between">
                  <div className="mb-4 md:mr-2 md:mb-0">
                    <label
                      className="block mb-2 text-sm font-bold text-gray-700"
                      htmlFor="userid"
                    >
                      {intl.formatMessage({
                        id: "user.userid",
                        defaultMessage: "...",
                      })}
                    </label>
                    <input
                      className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                      id="userid"
                      type="text"
                      placeholder=""
                      {...register("userid")}
                    />
                  </div>
                  <div className="md:ml-2">
                    <label
                      className="block mb-2 text-sm font-bold text-gray-700"
                      htmlFor="username"
                    >
                      {intl.formatMessage({
                        id: "user.username",
                        defaultMessage: "...",
                      })}
                    </label>
                    <input
                      className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                      id="username"
                      type="text"
                      placeholder=""
                      {...register("username")}
                    />
                  </div>
                </div>
                <div className="mb-4">
                  <label
                    className="block mb-2 text-sm font-bold text-gray-700"
                    htmlFor="email"
                  >
                    {intl.formatMessage({
                      id: "user.email",
                      defaultMessage: "...",
                    })}
                  </label>
                  <input
                    className="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                    id="email"
                    type="email"
                    placeholder=""
                    {...register("email")}
                  />
                  <p>{errors.email?.message}</p>
                </div>
                <div className="mb-4">
                  <label
                    className="block mb-2 text-sm font-bold text-gray-700"
                    htmlFor="mobile"
                  >
                    {intl.formatMessage({
                      id: "user.mobile",
                      defaultMessage: "...",
                    })}
                  </label>
                  <input
                    className="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                    id="mobile"
                    type="text"
                    placeholder=""
                    {...register("mobile")}
                  />
                  <p>{errors.mobile?.message}</p>
                </div>
                <div className="mb-4 md:flex md:justify-between">
                  <div className="mb-4 md:mr-2 md:mb-0">
                    <label
                      className="block mb-2 text-sm font-bold text-gray-700"
                      htmlFor="password"
                    >
                      {intl.formatMessage({
                        id: "user.password",
                        defaultMessage: "...",
                      })}
                    </label>
                    <input
                      className="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border XXXXborder-red-500 rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                      id="password"
                      type="password"
                      placeholder="******************"
                      {...register("password")}
                    />
                    <p>{errors.password?.message}</p>
                    {/* <p className="text-xs italic text-red-500">
                    {
                      intl.formatMessage({
                        id: "user.passwordchoose",
                        defaultMessage: '...'
                      })
                    }
                  </p> */}
                  </div>
                  <div className="md:ml-2">
                    <label
                      className="block mb-2 text-sm font-bold text-gray-700"
                      htmlFor="c_password"
                    >
                      {intl.formatMessage({
                        id: "user.passwordconfirm",
                        defaultMessage: "...",
                      })}
                    </label>
                    <input
                      className="w-full px-3 py-2 mb-3 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                      id="c_password"
                      type="password"
                      placeholder="******************"
                      {...register("passwordconfirm")}
                    />
                    <p>{errors.passwordconfirm?.message}</p>
                  </div>
                </div>
                <div className="mb-6 text-center">
                  <RenamedCaptcha
                    sitekey={process.env.REACT_APP_HCAPTCHA_KEY || ""}
                    onVerify={(token) => {
                      setCaptchaToken(token);
                    }}
                  />
                  <input
                    id="captchaToken"
                    type="hidden"
                    value={captchaToken}
                    {...register("captchaToken")}
                  />
                  <button
                    className="w-full px-4 py-2 font-bold text-white bg-indigo-500 rounded-full hover:bg-blue-700 focus:outline-none focus:shadow-outline"
                    type="submit"
                  >
                    {intl.formatMessage({
                      id: "user.registernow",
                      defaultMessage: "...",
                    })}
                  </button>
                </div>
                <hr className="mb-6 border-t" />
                {/* <div className="text-center">
                <a
                  className="inline-block text-sm text-blue-500 align-baseline hover:text-blue-800"
                  href="#"
                >
                  Forgot Password?
                </a>
              </div> */}
                <div className="text-center">
                  <Link
                    to="/login"
                    className="inline-block text-sm text-blue-500 align-baseline hover:text-blue-800"
                    href="/login"
                  >
                    {intl.formatMessage({
                      id: "user.haveaccountlogin",
                      defaultMessage: "...",
                    })}
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
        {hasError ? (
          <Modal
            setHasError={setHasError}
            msg={"Chosen userid is not available. Please use another one."}
          />
        ) : null}
      </div>

      {/* <DebugTableWeb vars={{
        'items': [
          {
            key: 'router params',
            value: params,
            type: 'JSON'
          },
          {
            key: 'inquiryText',
            value: inquiryText,
            type: 'string'
          }
        ]
      }} /> */}
    </>
  );
}

export default TemplateUserRegister;
