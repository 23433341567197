import React, { useContext, useEffect, useState } from "react";

import {
  BASEURL,
  APIURL,
  lines,
  stations,
  listEstate,
  apiQueryGetEstate,
  apiQueryOneEstate,
  addOrder,
  apiAddUser,
  apiUserLogin,
} from "./api/api";
import {
  EstateDataType,
  DataType,
  StationLineType,
  DataContextType,
  ItemType,
  SubmissionType,
  UserType,
} from "./estate-type";

const defaultUser = {
  userid: "a000",
  password: "xxx",
  username: "anonymous",
  email: "xxx@yyy.net",
  mobile: "99980958",
  meta: {},
  passwordconfirm: "xxx",
  captchaToken: "",
};

// Solve: Context type undefine
// ref: https://www.carlrippon.com/react-context-with-typescript-p4/
export const EstateContext = React.createContext<DataContextType>(undefined!);

export const EstateContextProvider = (props: any) => {
  const [data, setData] = useState<DataType>({
    estates: [],
    total: 0,
    stations: stations,
    lines: lines,
  });

  const [routeLocation, setRouteLocation] = useState("/");
  const [contextLang, setContextLang] = useState(
    process.env.REACT_APP_DEFAULT_LANG || "zh-hk"
  );
  const [currentLine, setCurrentLine] = useState(0);
  const [currentStation, setCurrentStation] = useState(0);
  const [currentUser, setCurrentUser] = useState<UserType>(defaultUser);
  const [cartItems, setCartItems] = useState<Array<any>>([]);

  const [localSubmission, setLocalSubmission] = useState<Array<any>>([]);
  const [localUserList, setLocalUserList] = useState<Array<any>>([]);

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(3);
  const [isLoadingList, setIsLoadingList] = useState(true);

  /**
   * Initial landing page
   */
  useEffect(() => {
    (async function () {
      let ajson: any;
      if ("/" === window.location.pathname) {
        ajson = await apiQueryGetEstate(page, size, currentStation);
      } else {
        console.log(
          `Route Path: ${window.location.pathname}, ${
            "/" === window.location.pathname
          }`
        );
      }

      // console.log(`estates_json_landing `, ajson)
      // console.log(`apiQueryGetEstate currentStation: ${currentStation}`)

      setData({
        estates: ajson?.estates || [],
        total: ajson?.total,
        stations: stations,
        lines: lines,
      });
      setIsLoadingList(false);
    })();
  }, [page, size, currentStation]);

  // Local Storage: setting & getting data
  // Do only once
  useEffect(() => {
    const readCartItem: string = localStorage.getItem("cartItems") || "[]";
    const cartItemsData = JSON.parse(readCartItem);

    // TODO: use cookies user
    const readCurrentUser: string =
      localStorage.getItem("currentUser") || JSON.stringify(defaultUser);
    const currentUserData = JSON.parse(readCurrentUser);

    const readLocalSubmission: string =
      localStorage.getItem("localSubmission") || "[]";
    const localSubmissionData = JSON.parse(readLocalSubmission);

    const readContextLang: string = localStorage.getItem("locale") || "zh-hk";
    const localContextLang = readContextLang;

    if (readCartItem !== "[]" && cartItemsData) {
      setCartItems(cartItemsData);
    }
    if (readCurrentUser !== JSON.stringify(defaultUser) && currentUserData) {
      setCurrentUser(currentUserData);
    }
    if (readLocalSubmission !== "[]" && localSubmissionData) {
      setLocalSubmission(localSubmissionData);
    }
    if (readContextLang && localContextLang) {
      setContextLang(localContextLang);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);
  useEffect(() => {
    localStorage.setItem("currentUser", JSON.stringify(currentUser));
  }, [currentUser]);
  useEffect(() => {
    localStorage.setItem("localSubmission", JSON.stringify(localSubmission));
  }, [localSubmission]);

  function addToCart(newItem: ItemType) {
    setCartItems((prevItems: ItemType[]) => [...prevItems, newItem]);
  }

  function removeFromCart(id: number) {
    setCartItems((prevItems) =>
      prevItems.filter((item) => item.estate_id !== id)
    );
  }

  function addSubmission(newItem: SubmissionType) {
    // TODO: submit to backend database via API call
    setLocalSubmission((prevItems: SubmissionType[]) => [
      ...prevItems,
      newItem,
    ]);
  }

  async function addUser(newItem: UserType) {
    setLocalUserList((prevItems: UserType[]) => [...prevItems, newItem]);
    return await apiAddUser(newItem);
  }

  return (
    <EstateContext.Provider
      value={{
        data,
        setData,
        contextLang,
        setContextLang,
        currentLine,
        currentStation,
        setCurrentLine,
        setCurrentStation,
        setCartItems,
        addToCart,
        cartItems,
        removeFromCart,
        localSubmission,
        addSubmission,
        currentUser,
        setCurrentUser,
        localUserList,
        addUser,
        page,
        setPage,
        size,
        setSize,
        isLoadingList,
        setIsLoadingList,
        listEstate,
        apiQueryGetEstate,
        apiQueryOneEstate,
        addOrder,
        apiUserLogin,
      }}
    >
      {props.children}
    </EstateContext.Provider>
  );
};

export default EstateContext;
