import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import { ImageAlbumProps } from "../../estate-type";

import LightGallery from "lightgallery/react";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { ImageContainer } from "../atoms/ImageContainer";

// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";

export const ImageAlbum = ({ slides }: ImageAlbumProps) => {
  const lightGallery = useRef<any>(null);

  const onInit = useCallback((detail) => {
    console.log("lightGallery has been initialized");
    if (detail) {
      lightGallery.current = detail.instance;
    }
  }, []);

  const getItems = useCallback(() => {
    return slides.map((o: any, idx: number) => {
      return <ImageContainer src={o} alt={""} key={idx}></ImageContainer>;
    });
  }, [slides]);

  useEffect(() => {
    lightGallery.current.refresh();
  }, [slides]);

  return (
    <div className="ImageTrack px-2 py-4 overflow-auto">
      <div className="ImageAlbum flex text-left">
        <LightGallery
          elementClassNames="flex flex-0 flex-nowrap -ml-2"
          onInit={onInit}
          speed={500}
          plugins={[lgThumbnail, lgZoom]}
        >
          {getItems()}
        </LightGallery>
      </div>
    </div>
  );
};
