import React, { useContext, useEffect, useState, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { useCookies } from "react-cookie";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  default as RenamedCaptcha,
  OKSTR,
  COOKIE_SESSION_NAME,
} from "../../api/api";
import { EstateCardProps, DataType, DataContextType } from "../../estate-type";
import EstateContext from "../../estate-context";

import { DebugTableWeb } from "../organisms/DebugTableWeb";
import { UserCard } from "../molecules/UserCard";
import { EstateCard } from "../molecules/EstateCard";
import { AnswerContainer } from "../molecules/AnswerContainer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark } from "@fortawesome/free-solid-svg-icons";

interface ParamTypes {
  id: string;
  name: string;
}

function TemplateForm() {
  const intl = useIntl();

  const {
    data,
    currentLine,
    currentStation,
    setCurrentLine,
    setCurrentStation,
    addToCart,
    cartItems,
    removeFromCart,
    addSubmission,
    currentUser,
    addOrder,
    apiQueryOneEstate,
  } = useContext<DataContextType>(EstateContext);

  let history = useHistory();
  const params = useParams<ParamTypes>();
  const { id } = params;
  // TODO: define constant
  const [cookies, setCookie] = useCookies([COOKIE_SESSION_NAME, "userid"]);
  const [currentItem, setCurrentItem] = useState<EstateCardProps>();
  const [inquiryText, setInquiryText] = useState<string>("");
  const [additionalAns, setAdditionalAns] = useState({});
  const [captchaToken, setCaptchaToken] = useState("");

  let isCarted = cartItems.filter(
    (o: any, idx: number) => o.estate_id === currentItem?.estate_id
  ).length;

  /**
   * fetch single item(estate)
   */
  useEffect(() => {
    (async function () {
      let ajson: any = await apiQueryOneEstate(id);
      let filteredItem = ajson?.estates.filter(
        (o: any, idx: number) => o.estate_id.toString() === id.toString()
      );

      if (filteredItem && filteredItem.length) {
        setCurrentItem(filteredItem[0]);
      }
    })();
  }, []);

  useEffect(() => {
    (async function () {
      // TODO: put back to submit button
      // addOrder(id, cookies['userid'], '', cookies)
    })();
  }, []);

  const schema = yup
    .object({
      userid: yup.string().required(),
      username: yup.string().required(),
      password: yup.string().min(6).required(),
      email: yup.string().email(),
      mobile: yup
        .number()
        .positive()
        .integer()
        .min(10000000)
        .max(99999999)
        .required(),
      passwordconfirm: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match"),
      captchaToken: yup.string(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: any) => {
    data["captchaToken"] = captchaToken;
    console.log(`Order Form `, data);
    let status: any = await addOrder(data);
    if (OKSTR === status) {
      history.push("/user-register-success");
    } else {
      // TODO: pop modal to notify user the registration failed
      // setHasError(true);
      console.log(`[Error] Order: check userid`, status);
    }
  };

  return (
    <>
      <div className="Heading flex">
        <a
          className="w-16 m-2 px-2 py-1 border rounded"
          href="#"
          onClick={() => {
            // TODO: need to resolve going back, retain filter history
            // history.goBack();

            // this is work around 2021-11-13
            history.push("/");
          }}
        >
          {intl.formatMessage({
            id: "common.goback",
            defaultMessage: "...",
          })}
        </a>
        <h1 className="w-full m-2 text-2xl font-bold text-indigo-400">
          {intl.formatMessage({
            id: "order.heading",
            defaultMessage: "...",
          })}
        </h1>
      </div>

      <div className="Body px-4">
        <div className="grid Xmd:grid-cols-2 gap-6">
          <EstateCard {...currentItem} />
          <div className="border focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1">
            <div className="-mt-4 absolute tracking-wider px-1 uppercase text-xs">
              <p>
                <label
                  htmlFor="interestedItem"
                  className="bg-white text-gray-600 px-1"
                >
                  {intl.formatMessage({
                    id: "order.interesteditem",
                    defaultMessage: "...",
                  })}
                </label>
              </p>
            </div>
            <p>
              <input
                id="interestedItem"
                autoComplete="false"
                tabIndex={0}
                type="text"
                className="py-1 px-1 outline-none block h-full w-full"
                readOnly
                value={
                  "#" +
                  (currentItem?.estate_id || "") +
                  " " +
                  (currentItem?.title || "")
                }
              />
            </p>
          </div>
          <div className="border focus-within:border-blue-500 focus-within:text-blue-500 transition-all duration-500 relative rounded p-1">
            <div className="-mt-4 absolute tracking-wider px-1 uppercase text-xs">
              <p>
                <label
                  htmlFor="inquiryText"
                  className="bg-white text-gray-600 px-1"
                >
                  {intl.formatMessage({
                    id: "order.inquirytext",
                    defaultMessage: "...",
                  })}
                </label>
              </p>
            </div>
            <p>
              <textarea
                id="inquiryText"
                autoComplete="false"
                tabIndex={0}
                className="py-1 px-1 outline-none block h-full w-full"
                value={inquiryText}
                onChange={(e) => {
                  setInquiryText(e.target.value);
                }}
              />
            </p>
          </div>
        </div>

        <div className="AdditionalRequest my-8">
          <h3 className="text-lg text-indigo-500">
            {intl.formatMessage({
              id: "order.additional_request",
              defaultMessage: "...",
            })}
          </h3>
          {currentItem?.additional_request &&
            JSON.parse(currentItem?.additional_request).map(
              (o: any, idx: number) => {
                return (
                  <AnswerContainer
                    id={""}
                    text={o}
                    setAns={(text: string) => {
                      setAdditionalAns({
                        ...additionalAns,
                        ["q" + idx]: text,
                      });
                    }}
                    {...o}
                    key={idx}
                  />
                );
              }
            )}
        </div>

        {
          // TODO: use agent
        }
        <UserCard firstName={currentItem?.username || ""} />
        {/* <UserCard
          firstName={currentUser.userid}
          // lastName={currentUser.username}
          // userName={currentUser.username}
          // contactNum={currentUser.mobile}
        /> */}
      </div>

      <div className="sticky bottom-0 mt-6 p-4 bg-white shadow-lg">
        <button
          className={
            `mx-2 p-2 leading-5 appearance-none hover:bg-green-300 rounded-md text-gray-100 bg-blue-600 hover:shadow-inner hover:bg-blue-700 transition-all duration-300 ` +
            (currentItem?.estate_id ? "" : "disabled:opacity-50")
          }
          disabled={!currentItem?.estate_id}
          onClick={() => {
            let msg: string = JSON.stringify({
              inquiryText: inquiryText,
              ...additionalAns,
            });
            if (currentItem?.estate_id) {
              addOrder(
                currentItem?.estate_id,
                currentUser.userid,
                msg,
                cookies
              );
              // addSubmission({
              //   currentItem,
              //   currentUser,
              //   additionalInfo: {
              //     inquiryText,
              //   },
              //   ts: Date.now().toString(),
              // });

              // TODO: [urgent] check if the server received succesfully
              history.push("/submitsuccess");
            }
          }}
        >
          {intl.formatMessage({
            id: "order.submitinquiry",
            defaultMessage: "...",
          })}
        </button>

        <button
          className={
            "mx-2 p-2 leading-5 appearance-none rounded-md transition-all duration-300 " +
            (isCarted
              ? "text-indigo-600 hover:text-white bg-white hover:bg-indigo-300"
              : "text-white bg-indigo-500 hover:bg-indigo-700")
          }
          onClick={(e) => {
            if (isCarted) {
              removeFromCart(currentItem?.estate_id);
            } else {
              addToCart(currentItem);
            }
          }}
        >
          <FontAwesomeIcon icon={faBookmark} />
          {intl.formatMessage({
            id: "estate.bookmark",
            defaultMessage: "...",
          })}
        </button>
      </div>

      {/* <DebugTableWeb vars={{
        'items': [
          {
            key: 'router params',
            value: params,
            type: 'JSON'
          },
          {
            key: 'inquiryText',
            value: inquiryText,
            type: 'string'
          }
        ]
      }} /> */}
    </>
  );
}

export default TemplateForm;
