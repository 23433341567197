import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { parseISO, format } from "date-fns";

import { DataContextType, EstateCardProps } from "../../estate-type";
import EstateContext from "../../estate-context";
import { lines, stations } from "../../api/api";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

import { Pill } from "../atoms/Pill";
import { ImageAlbum } from "./ImageAlbum";

export const EstateCard = ({
  estate_id,
  exchange_status,
  title,
  district,
  station,
  distance,
  description,
  flat_age,
  price_range,
  genre,
  image_link,
  caddress,
  eaddress,
  lat,
  lng,
  visit_slot,
  meta,
  addtime,
  userid,

  username,
  spacious,
  can_pet,
  isaccessibility,
  can_smoke,
  additional_request,
}: EstateCardProps) => {
  const intl = useIntl();

  const { data, contextLang, setContextLang } =
    useContext<DataContextType>(EstateContext);

  let atext: string = image_link || "[]";
  let album = JSON.parse(atext);

  let stationArr = stations.filter((o: any) => o?.id === station);
  let loadLang: string =
    contextLang === process.env.REACT_APP_DEFAULT_LANG ? "cname" : "ename";
  let stationItem = stationArr ? stationArr[0] : null;
  let stationText = stationItem && stationItem[loadLang];

  return (
    <div className="EstateCard mb-4 flex flex-col text-left">
      <ImageAlbum slides={album}></ImageAlbum>
      <h2 className="flex text-xl font-bold">{title}</h2>

      <div className="flex my-4">
        <Pill text={district ? `${district}` : "-"} color={""} bgColor={""} />
        <Pill
          text={
            stationText
              ? `${stationText}${intl.formatMessage({
                  id: "common.station",
                  defaultMessage: "...",
                })}`
              : "-"
          }
          color={""}
          bgColor={""}
        />
        <Pill text={distance ? `${distance}` : "-"} color={""} bgColor={""} />
      </div>

      <p className="text-sm text-gray-400">
        {addtime ? format(parseISO(addtime), "yyyy-MM-dd HH:mm") : null}
      </p>

      <p>
        {intl.formatMessage({
          id: "estatecard.spacious",
          defaultMessage: "...",
        })}
        {spacious}
      </p>

      <p>
        {intl.formatMessage({
          id: "estatecard.built",
          defaultMessage: "...",
        })}
        {`${flat_age || "-"} `}
        {intl.formatMessage({
          id: "estatecard.year",
          defaultMessage: "...",
        })}
      </p>

      <p>{description}</p>

      <p>
        {intl.formatMessage({
          id: "estatecard.address",
          defaultMessage: "...",
        })}
        {contextLang === process.env.REACT_APP_DEFAULT_LANG
          ? caddress
          : eaddress}
      </p>

      <div className="grid grid-cols-2 p-4 border border-gray-200 rounded">
        <label>
          <span>
            {intl.formatMessage({
              id: "estatecard.allowpets",
              defaultMessage: "...",
            })}
          </span>
          <input
            className="mx-1"
            type="checkbox"
            readOnly
            checked={!!can_pet}
          />
        </label>

        <label>
          <span>
            {intl.formatMessage({
              id: "estatecard.allowsmoke",
              defaultMessage: "...",
            })}
          </span>
          <input
            className="mx-1"
            type="checkbox"
            readOnly
            checked={!!can_smoke}
          />
        </label>
      </div>

      <p className="">
        <span className="">
          {intl.formatMessage({
            id: "common.monetaryunit",
            defaultMessage: "...",
          })}
        </span>
        <span className="mx-2 text-xl font-bold text-red-500">
          {price_range}
        </span>
      </p>
    </div>
  );
};
