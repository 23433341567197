import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Driver from "driver.js";

import { DataType, DataContextType } from "../../estate-type";
import EstateContext from "../../estate-context";

import Navbar from "../molecules/Navbar";
import DisplayListing from "../organisms/DisplayListing";
import DistrickContainer from "../organisms/DistrictContainer";

import "driver.js/dist/driver.min.css";

function TemplateHome() {
  const intl = useIntl();

  const { isLoadingList } = useContext<DataContextType>(EstateContext);
  let driver = new Driver({});

  useEffect(() => {
    // Run Driver on component mount
    driver = new Driver({
      doneBtnText: intl.formatMessage({
        id: "common.tour.done",
        defaultMessage: "...",
      }),
      closeBtnText: intl.formatMessage({
        id: "common.tour.close",
        defaultMessage: "...",
      }),
      nextBtnText: intl.formatMessage({
        id: "common.tour.next",
        defaultMessage: "...",
      }),
      prevBtnText: intl.formatMessage({
        id: "common.tour.previous",
        defaultMessage: "...",
      }),
    });

    driver.defineSteps([
      {
        element: ".gFilter",
        popover: {
          className: "first-step-popover-class",
          title: intl.formatMessage({
            id: "home.tour.filter.heading",
            defaultMessage: "...",
          }),
          description: intl.formatMessage({
            id: "home.tour.filter.description",
            defaultMessage: "...",
          }),
          position: "bottom",
        },
      },
      {
        element: ".gBookmark",
        popover: {
          title: intl.formatMessage({
            id: "home.tour.bookmark.heading",
            defaultMessage: "...",
          }),
          description: intl.formatMessage({
            id: "home.tour.bookmark.description",
            defaultMessage: "...",
          }),
          position: "bottom",
        },
      },
      {
        element: ".gContact",
        popover: {
          title: intl.formatMessage({
            id: "home.tour.contact.heading",
            defaultMessage: "...",
          }),
          description: intl.formatMessage({
            id: "home.tour.contact.description",
            defaultMessage: "...",
          }),
          position: "bottom",
        },
      },
    ]);

    // Start the introduction
    let hasShownTutorial = true;
    if (!isLoadingList && !hasShownTutorial) {
      driver.start();
    }
  }, [isLoadingList]);

  return (
    <>
      <h1 className="hidden md:block w-full p-4 text-2xl font-bold text-indigo-400">
        {intl.formatMessage({
          id: "app.slogan",
          description: "Countdown HomeScreen",
          defaultMessage: "...",
          // values: {n: time},
        })}
      </h1>
      {/* {driver && driver.isActivated
        ? "+" + driver.getHighlightedElement()?.getNode().textContent
        : null} */}
      <DistrickContainer />
      <DisplayListing />
    </>
  );
}

export default TemplateHome;
