import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

import { ItemType, ListingItemProps, DataContextType } from "../../estate-type";
import EstateContext from "../../estate-context";

export const Footer = ({ children, setHasError, msg }: any) => {
  const intl = useIntl();

  const { data } = useContext<DataContextType>(EstateContext);

  return (
    <footer className="flex flex-col p-4 pb-16">
      <div className="flex mb-4">
        <Link to="/contactus">
          {intl.formatMessage({
            id: "footer.contactus",
            defaultMessage: "...",
          })}
        </Link>
        <br />
        <p>&copy; Rentant All rights reserved.</p>
      </div>

      <div className="flex">
        <img className=" h-6 mr-4" src="/fps.png" height="32" alt="fps" />
        <img className=" h-6 mr-4" src="/payme.png" height="32" alt="payme" />
        <img className=" h-6 mr-4" src="/paypal.png" height="32" alt="paypal" />
      </div>
    </footer>
  );
};
